import React from "react";
import logo from "../assests/footerlogo.svg";
import { IoLogoLinkedin } from "react-icons/io5";
import { IoLogoFacebook } from "react-icons/io5";
import { FaXTwitter } from "react-icons/fa6";
import { ImBehance2 } from "react-icons/im";
import { AiFillMediumSquare } from "react-icons/ai";
import { FaInstagram } from "react-icons/fa";
import ClutchWidget from "./LandingPage/ClutchWidget";
import { useNavigate, useLocation } from "react-router-dom";

const FooterSection = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (path, isExternal = false) => {
    if (isExternal) {
      window.open(path, "_blank", "noopener noreferrer");
      return;
    }
    if (path === "/services") {
      if (location.pathname === "/") {
        // If already on landing page, scroll to services
        document
          .getElementById("services-section")
          ?.scrollIntoView({ behavior: "smooth" });
      } else {
        // If on another page, navigate to home and then scroll to services
        navigate("/");
        // Store a flag in session storage to indicate we need to scroll to services
        sessionStorage.setItem("scrollToServices", "true");
      }
    } else {
      navigate(path);
    }
  };

  return (
    <div className="bg-primary">
      <div className="w-full bg-primary flex flex-col lg:px-[100px]  larger:px-[200px] mx-auto max-w-[2000px] lg:py-16  px-3 py-12">
        <div className="flex lg:flex-row lg:space-x-10 flex-col lg:space-y-0 space-y-8">
          <div className="w-full">
            <div>
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
            </div>
            <div className="mt-4">
              <p className="text-lg text-white max-w-[390px]">
                <span>
                  <a href="mailto:Hai@cogntix.com">hai@cogntix.com</a>
                </span>
                <br />
                No- 218, Stanley road, Jaffna, Sri Lanka. <br />
                <span style={{ textDecoration: "none" }}>
                  (+1) 205 316 9387, (+94) 77 444 1787
                </span>
              </p>
            </div>
            <div className="mt-6">
              <ClutchWidget />
            </div>
          </div>

          <div className="w-full flex lg:justify-end">
            <div className="flex lg:flex-row flex-col gap-10 lg:gap-24">
              <div>
                <h2 className="text-secondary text-xl font-semibold uppercase mb-6">
                  Services
                </h2>
                <div className="flex flex-col space-y-3">
                  <p
                    onClick={() => handleNavigate("/services")}
                    className="footerlink whitespace-nowrap cursor-pointer"
                  >
                    UI/UX design
                  </p>
                  <p
                    onClick={() => handleNavigate("/services")}
                    className="footerlink whitespace-nowrap cursor-pointer"
                  >
                    Web development
                  </p>
                  <p
                    onClick={() => handleNavigate("/services")}
                    className="footerlink whitespace-nowrap cursor-pointer"
                  >
                    Mobile app development
                  </p>
                  <p
                    onClick={() => handleNavigate("/services")}
                    className="footerlink whitespace-nowrap cursor-pointer"
                  >
                    SaaS development
                  </p>
                  <p
                    onClick={() => handleNavigate("/services")}
                    className="footerlink whitespace-nowrap cursor-pointer"
                  >
                    Machine learning & AI
                  </p>
                  <p
                    onClick={() => handleNavigate("/services")}
                    className="footerlink whitespace-nowrap cursor-pointer"
                  >
                    Quality assurance
                  </p>
                </div>
              </div>

              <div>
                <h2 className="text-secondary text-xl font-semibold uppercase mb-6">
                  Our Works
                </h2>
                <div className="flex flex-col space-y-3">
                  <p
                    onClick={() => handleNavigate("/ourworks/Feasto", true)}
                    className="footerlink whitespace-nowrap cursor-pointer"
                  >
                    FEASTO
                  </p>
                  <p
                    onClick={() => handleNavigate("/ourworks/Recipelab", true)}
                    className="footerlink whitespace-nowrap cursor-pointer"
                  >
                    Recipe labs
                  </p>
                  <p
                    onClick={() =>
                      handleNavigate(
                        "/ourworks/Customers-Bill-Management",
                        true
                      )
                    }
                    className="footerlink whitespace-nowrap cursor-pointer"
                  >
                    Bill management app
                  </p>
                  <p
                    onClick={() =>
                      handleNavigate("/ourworks/Parcel-Delivery-System", true)
                    }
                    className="footerlink whitespace-nowrap cursor-pointer"
                  >
                    Delivery management system
                  </p>
                  <p
                    onClick={() =>
                      handleNavigate(
                        "/ourworks/AI-Powered-Query-Platform",
                        true
                      )
                    }
                    className="footerlink whitespace-nowrap cursor-pointer"
                  >
                    AI-powered query
                  </p>
                </div>
              </div>

              <div>
                <h2 className="text-secondary text-xl font-semibold uppercase mb-6">
                  Company
                </h2>
                <div className="flex flex-col space-y-3">
                  <p
                    onClick={() => handleNavigate("/subscriptions")}
                    className="footerlink whitespace-nowrap cursor-pointer"
                  >
                    Subscriptions
                  </p>
                  <p
                    onClick={() => handleNavigate("/careers")}
                    className="footerlink whitespace-nowrap cursor-pointer"
                  >
                    Careers
                  </p>
                  <p
                    onClick={() => handleNavigate("/blogs")}
                    className="footerlink whitespace-nowrap cursor-pointer"
                  >
                    Blogs
                  </p>
                  <p
                    onClick={() => handleNavigate("/privacypolicy", true)}
                    className="footerlink whitespace-nowrap cursor-pointer"
                  >
                    Privacy policy
                  </p>
                  <p
                    onClick={() => handleNavigate("/termsandconditions", true)}
                    className="footerlink whitespace-nowrap cursor-pointer"
                  >
                    Terms & conditions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr className="mt-12 mb-6" />

        <div className=" flex lg:flex-row flex-col lg:space-y-0 space-y-6 justify-between items-center text-center">
          <div>
            <p className="text-white text-lg">
              <p>© 2024 Cogntix. All rights reserved.</p>
            </p>
          </div>
          <div className="flex flex-row gap-6">
            <div className="">
              <a
                href="https://www.linkedin.com/company/cogntix/posts/?feedView=all"
                rel="noreferrer"
                target="blank"
              >
                <IoLogoLinkedin className="text-white size-6 hover:text-secondary" />
              </a>
            </div>
            <div className="">
              <a
                href="https://www.facebook.com/WeCogntix?mibextid=LQQJ4d"
                rel="noreferrer"
                target="blank"
              >
                <IoLogoFacebook className="text-white size-6  hover:text-secondary" />
              </a>
            </div>
            <div className="">
              <a
                href="https://www.instagram.com/cogntix?igsh=MWN3Mno2cWZqNzFpeQ=="
                rel="noreferrer"
                target="blank"
              >
                <FaInstagram className="text-white size-6  hover:text-secondary" />
              </a>
            </div>
            <div className="">
              <a
                href="https://x.com/cogntix?s=11&t=pH_wvA9sD9HGsNEOTYd5lA"
                rel="noreferrer"
                target="blank"
              >
                <FaXTwitter className="text-white size-6  hover:text-secondary" />
              </a>
            </div>
            <div className="">
              <a
                href="https://www.behance.net/cogntixdesign"
                rel="noreferrer"
                target="blank"
              >
                <ImBehance2 className="text-white size-6  hover:text-secondary" />
              </a>
            </div>
            <div className="">
              <a
                href="https://medium.com/@cogntix"
                rel="noreferrer"
                target="blank"
              >
                <AiFillMediumSquare className="text-white size-6  hover:text-secondary" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
