import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import groupImage from "../assests/GroupImage.png";
import ClutchWidget from "../components/LandingPage/ClutchWidget";
import FeaturesSection from "../components/LandingPage/FeaturesSection";
import StatsSection from "../components/LandingPage/StatsSecton";
import Projects from "../components/LandingPage/Projects";
import Services from "../components/LandingPage/Services";
import Testimonials from "../components/LandingPage/Testimonials";
import DemoCallSection from "../components/LandingPage/DemoCallSection";
import FooterSection from "../components/FooterSection";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const LandingPageMainScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const shouldScrollToServices = sessionStorage.getItem("scrollToServices");
    if (shouldScrollToServices) {
      sessionStorage.removeItem("scrollToServices");
      setTimeout(() => {
        document
          .getElementById("services-section")
          ?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, []);

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <>
      <Helmet>
        <title>Cogntix | Your Partner for Digital Transformation</title>
        <meta
          name="description"
          content="At Cogntix, we are a passionate team of tech enthusiasts driven by our commitment to revolutionizing the digital landscape."
        />
        <meta
          name="keywords"
          content="Software solutions, Business software development, Digital transformation, Custom software services, IT support, Software company, technology solutions, software development services, Software companies in Jaffna, Jaffna Software company"
        />
      </Helmet>
      <Navbar />
      <div className="h-auto bg-white lg:mt-[120px] mt-[60px] lg:px-[100px] larger:px-[200px] larger:mx-auto max-w-[2000px] mx-2">
        <div className="flex lg:flex-row flex-col lg:space-x-[60px] space-y-10 mb-16">
          <div className="flex flex-col lg:mt-[10%] mt-[100px] lg:w-[50%]">
            <h2 className="semiHeading text-left">COGNTIX</h2>
            <h1 className="heading1 mb-6 lg:max-w-[600px]">
              Empower Business with AI Powered Tech Solutions
            </h1>
            <p className="lg:w-[95%] text-text2 text-lg mb-8">
              We streamlines your digital transformation with custom software
              and AI-driven solutions, ensuring impactful results and seamless
              user experiences through our expert team.
            </p>
            <button
              onClick={() => handleNavigate("/ourworks")}
              className="group bg-primary rounded-lg px-6 py-3 text-white text-lg lg:w-[40%] mb-8 flex justify-center items-center gap-3"
            >
              Explore Our Works
              <FaArrowRightLong className="group-hover:inline hidden" />
            </button>
            <ClutchWidget />
          </div>
          <div className="flex justify-end lg:w-[50%]">
            <img
              src={groupImage}
              alt="groupImage"
              className="w-full lg:max-h-[800px] lg:max-w-[600px]"
            />
          </div>
        </div>
        <div className="w-full mb-12">
          <FeaturesSection />
        </div>
      </div>
      <StatsSection />
      <Projects />
      <div id="services-section">
        <Services />
      </div>
      <Testimonials />
      <DemoCallSection />
      <FooterSection handleNavigate={handleNavigate} />
    </>
  );
};

export default LandingPageMainScreen;
